<script lang="ts" setup></script>

<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.0182 16.5L4.5 9.11355L6.17883 7.5L6.98175 8.3247L12.0182 13.2371L17.8577 7.5L19.5 9.11355L12.8212 15.6753L12.0182 16.5Z"
        />
    </svg>
</template>
